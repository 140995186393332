import { debug } from "settings";
import { appContext } from "store";

import { endSession } from "./api";
import { IgnoreMe } from "./api/errors";

export const catchError =
  (wrapped) =>
  async (...args) => {
    try {
      await wrapped(...args);
    } catch (error) {
      if (!(error instanceof IgnoreMe)) {
        if (debug) {
          throw error;
        } else {
          // TODO: Sentry
          appContext.setServiceDown();
        }
      }
    }
  };

export const isIE11 =
  Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);

export const logout = () => {
  endSession();
  appContext.reset();
};
