import { colors, font } from "../base";
import { header, footer } from "../styles";

import { ReactComponent as Banner } from "./static/banner.svg";
import optimusLogo from "./static/optimus-logo.png";

const code = "kiwi";
const name = "Kiwi Energy";

const theme = {
  brand: {
    code,
    name,
    url: "https://kiwienergy.us/",
  },
  colors,
  font,
  banners: {
    header: (
      <Banner
        title={`${name} Ecogold`}
        css={[
          header,
          {
            ".purple": { fill: "#5c4e90" },
          },
        ]}
      />
    ),
    footer: <Banner title={name} css={footer} />,
  },
  dashboardUrls: {
    systemProtection: "https://buycinch.com/kiwicp",
    applianceProtection: "https://buycinch.com/kiwiap",
    techSupport: "https://buycinch.com/kiwis",
  },
  optimusLogo,
};

export default theme;
