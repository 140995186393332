import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { defaultTheme, getThemeForBrand } from "themes";

export const programStatuses = {
  OPTIMUS: "optimus",
  REWARD: "reward",
  BOTH: "both",
};

const defaultState = {
  isAuthenticated: false,
  programStatus: undefined,
  isServiceDown: false,
  navHeight: 70,
  brandCode: undefined,
  theme: defaultTheme,
  messages: [],
  actions: [],
  isResolving: false,
};

const store = create(
  subscribeWithSelector((set) => ({
    ...defaultState,

    setAuthenticated: () => set({ isAuthenticated: true }),
    setNotAuthenticated: () => set({ isAuthenticated: false }),

    setProgramStatus: (programStatus) => set({ programStatus }),

    setServiceDown: () => set({ isServiceDown: true }),
    setServiceUp: () => set({ isServiceDown: false }),

    setNavHeight: (navHeight) => set({ navHeight }),

    setBrandCode: (brandCode) => set({ brandCode }),

    setTheme: (brandCode) => set({ theme: getThemeForBrand(brandCode) }),

    addMessage: (message) =>
      set(({ messages }) => ({ messages: [...messages, message] })),

    reset: () => set(defaultState),
  })),
);

export const appContext = store.getState();

export default store;
