import * as React from "react";

import isEqual from "lodash/isEqual";
import shallow from "zustand/shallow";

import useAppContext from ".";

const isAuthenticatedSelector = ({ isAuthenticated }) => isAuthenticated;
export const useIsAuthenticated = () => useAppContext(isAuthenticatedSelector);

const navHeightSelector = ({ navHeight }) => navHeight;
export const useNavHeight = () => useAppContext(navHeightSelector);

const themeSelector = ({ theme }) => theme;
export const useTheme = () => useAppContext(themeSelector);

const messagesSelector = ({ messages }) => messages;
export const useGetMessages = () => {
  const messages = useAppContext(messagesSelector);

  React.useEffect(() => {
    messages.length > 0 && useAppContext.setState({ messages: [] });
  }, [messages]);

  return messages;
};

const actionsSelector = ({ actions, isResolving }) => ({
  actions,
  isResolving,
});
export const useActions = () => {
  const { actions, isResolving } = useAppContext(actionsSelector, shallow);

  const resolve = React.useCallback(async () => {
    const prevActions = [...actions];
    await Promise.all(actions);
    useAppContext.setState(({ actions }) =>
      isEqual(prevActions, actions)
        ? { isResolving: false, actions: [] }
        : { isResolving: true, actions },
    );
  }, [actions]);

  return { isResolving, addAction, resolve };
};

const addAction = (action) =>
  useAppContext.setState(({ actions }) => ({
    actions: [...actions, action],
    isResolving: true,
  }));
