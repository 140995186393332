import { catchError } from "utils";
import { isAuthenticated } from "utils/api";

import { appContext } from ".";

export const setIsAuthenticated = catchError(async () => {
  (await isAuthenticated())
    ? appContext.setAuthenticated()
    : appContext.setNotAuthenticated();
});
