export class ResponseError extends Error {
  response;

  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export class NotAuthenticated extends ResponseError {}
export class ServiceError extends ResponseError {}

export class IgnoreMe extends Error {}
