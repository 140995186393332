import * as React from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import VisuallyHidden from "@reach/visually-hidden";

import { FlexCenteredRow as Row } from "components/styled";
import { FacebookBrands, InstagramBrands, TwitterBrands } from "icons";
import { borderRadius, noUnderline } from "styles";

import bbbLogo from "./static/bbb-logo.svg";

const Footer = () => (
  <footer
    css={(theme) => ({
      backgroundColor: theme.colors.gray[0],
      marginTop: "3rem",
      padding: "1rem 1rem",
    })}
  >
    <Content />
  </footer>
);

const Content = () => {
  const theme = useTheme();

  return (
    <>
      <Row>{theme.banners.footer}</Row>

      <Row css={{ marginBottom: "1.5rem" }}>
        <FALink
          href="https://www.facebook.com/pages/Ecogold/597693113595460?fref=ts"
          title="Facebook"
          icon={FacebookBrands}
        />
        <FALink
          href="https://twitter.com/Ecogold1"
          title="Twitter"
          icon={TwitterBrands}
          withBackground
        />
        <FALink
          href="https://instagram.com/ecogold1"
          title="Instagram"
          icon={InstagramBrands}
          withBackground
        />
      </Row>

      <Row css={{ fontSize: "0.8rem", flexWrap: "wrap", margin: "1.75rem 0" }}>
        {Object.entries({
          Privacy: "privacy",
          "Terms & Conditions": "terms-of-use",
          FAQs: "ecogold-faqs",
          Resources: "energy-saving-tips",
        }).map(([label, link]) => (
          <React.Fragment key={label}>
            <A href={`${theme.brand.url}${link}/`} css={noUnderline}>
              {label}
            </A>
            <div css={{ margin: "0 0.5em" }}>|</div>
          </React.Fragment>
        ))}
        <A href={`${theme.brand.url}contact/`} css={noUnderline}>
          Contact
        </A>
      </Row>

      <Row>
        <ImgLink
          href="https://www.bbb.org/new-york-city/business-reviews/energy-service-companies/kiwi-energy-ny-llc-in-brooklyn-ny-134140"
          src={bbbLogo}
          alt="BBB"
        />
        <ImgLink
          href="https://www.authorize.net/"
          src="https://www.authorize.net/content/dam/anet-redesign/reseller/authorizenet-200x50.png"
          alt="Authorize.Net"
        />
      </Row>

      <Row>
        <div
          css={{
            color: theme.colors.gray[7],
            fontSize: "0.65rem",
            lineHeight: "1.75em",
            maxWidth: 600,
          }}
        >
          Copyrighted business logos in the {theme.brand.name} Ecogold program
          are used with the company’s permission. They are not a sponsor or
          co-sponsor, and do not hold responsibility or liability for the
          program. See our resources section for terms and conditions of these
          businesses. This promotion is not produced, sponsored, or executed by
          Best Buy. BEST BUY, the BEST BUY logo and the tag design are
          trademarks of Best Buy and its affiliated companies &copy; 2015 Best
          Buy. All rights reserved. AMC&reg; is not a sponsor or co-sponsor of
          this promotion. The Bullseye Design, Target and Target GiftCards are
          registered trademarks of Target Brands, Inc. Terms and conditions are
          applied to gift cards. Target is not a participating partner in or
          sponsor of this offer. Amazon.com is not a sponsor of this promotion.
          Except as required by law, Amazon.com Gift Cards (“GCs”) cannot be
          transferred for value or redeemed for cash. GCs may be used only for
          purchases of eligible goods on Amazon.com or certain of its affiliated
          websites. For complete terms and conditions, see{" "}
          <A href="https://amazon.com/gc-legal">amazon.com/gc-legal</A>. GCs are
          issued by ACI Gift Cards, Inc., a Washington corporation. All Amazon
          &reg;, &trade; &amp; &copy; are IP of Amazon.com, Inc. or its
          affiliates. No expiration date or service fees. SUBWAY&reg; is a
          registered trademark of Doctor’s Associates, Inc. and is not a sponsor
          or co-sponsor of this program. Not liable for actual or alleged claims
          related to this offer. Use of the SUBWAY&reg; logos or word-marks must
          be approved prior to use. Terms and conditions of the SUBWAY&reg; Card
          apply and can be found at{" "}
          <A href="https://mysubwaycard.com/English/terms/RewardTerms">
            mysubwaycard.com
          </A>
          . Bed Bath &amp; Beyond&reg; is not a sponsor of this promotion. Terms
          and conditions apply to gift cards. Bed Bath &amp; Beyond is a
          registered trademark. &copy; 2015 Bed Bath &amp; Beyond Inc. and its
          subsidiaries. Regal Entertainment Group is not a sponsor or co-sponsor
          of this promotion and is not affiliated with this program and is not
          liable for any alleged or actual claims related to this offer. &copy;
          2015 All rights reserved – Regal Entertainment Group, Inc.{" "}
          {theme.brand.name} is not affiliated with The Home Depot&reg;. The
          Home Depot&reg; is not a sponsor of this promotion. The Home
          Depot&reg; is a registered trademark of Homer TLC, Inc.
        </div>
      </Row>
    </>
  );
};

const iconSize = "2.5rem";

const FALink = ({ href, title, icon: Icon, withBackground = false }) => {
  const { colors } = useTheme();
  const gray = colors.gray[6];

  return (
    <a {...{ href, title }} css={{ margin: "0 0.75rem" }}>
      <VisuallyHidden>{title}</VisuallyHidden>
      {withBackground ? (
        <div
          css={[
            borderRadius[3],
            {
              alignItems: "center",
              backgroundColor: gray,
              display: "flex",
              height: iconSize,
              justifyContent: "center",
              width: iconSize,
            },
          ]}
        >
          <Icon css={{ color: colors.gray[0], fontSize: "1.5rem" }} />
        </div>
      ) : (
        <Icon css={{ color: gray, fontSize: iconSize }} />
      )}
    </a>
  );
};

const ImgLink = ({ href, src, alt }) => (
  <a href={href}>
    <img
      src={src}
      alt={alt}
      css={{ height: 40, padding: "0 1rem" }}
      loading="lazy"
    />
  </a>
);

const A = styled.a(({ theme }) => ({
  color: theme.colors.gray[8],
  ":hover": {
    color: theme.colors.gray[9],
  },
}));

export default Footer;
