import facepaint from "facepaint";

export const fontSize = [12, 14, 16, 18, 20, 24, 30, 36].map((l) => `${l}px`);
export const spacing = [4, 8, 12, 16, 24, 32, 48, 64].map((l) => `${l}px`);

export const borderRadius = [".125rem", ".25rem", ".5rem", 9999].map(
  (borderRadius) => ({ borderRadius }),
);

export const boxShadow = [
  "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
].map((boxShadow) => ({ boxShadow }));

export const innerShadow = {
  boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
};

export const gray = [
  "#efefef",
  "#e2e2e3",
  "#d8d9da",
  "#bfc1c3",
  "#aaadb1",
  "#93999e",
  "#848d91",
  "#788589",
  "#6b7b83",
  "#5a7078",
];

export const noUnderline = {
  textDecoration: "none",
  ":hover": { textDecoration: "underline" },
};

export const screenReader = {
  position: "fixed !important",
  /* keep it on viewport */
  top: "0px !important",
  left: "0px !important",
  /* give it non-zero size, VoiceOver on Safari requires at least 2 pixels
     before allowing buttons to be activated. */
  width: "4px !important",
  height: "4px !important",
  /* visually hide it with overflow and opacity */
  opacity: "0 !important",
  overflow: "hidden !important",
  /* remove any margin or padding */
  border: "none !important",
  margin: "0 !important",
  padding: "0 !important",
  /* ensure no other style sets display to none */
  display: "block !important",
  visibility: "visible !important",
};

export const breakpoint = "@media (min-width: 500px)";
export const mq = facepaint([breakpoint]);

export const cartWidth = mq({
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: ["90%", 600],
});

export const ignoreSSRWarning =
  "/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */";
