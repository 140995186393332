import { navigate } from "@reach/router";

import { logout } from "utils";

const Logout = () => {
  logout();
  navigate("/");

  return null;
};

export default Logout;
