import { borderRadius, boxShadow, spacing } from ".";

export const global = (theme) => ({
  "*, *:before, *:after": { boxSizing: "inherit" },

  html: { boxSizing: "border-box" },

  body: { color: theme.colors.black, fontFamily: theme.font.family, margin: 0 },

  a: link(theme),

  button: button(theme),

  "h1, h2, h3": {
    color: theme.colors.secondary[3],
    fontWeight: "normal",
    margin: "0 0 .75em",
  },

  // Font Awesome
  "[data-fa-w-11]": { width: "0.6875em" },
  "[data-fa-w-14]": { width: "0.875em" },
  "[data-fa-w-18]": { width: "1.125em" },
  "[data-fa-w-20]": { width: "1.25em" },
});

export const button = (theme) => ({
  ...borderRadius[3],
  ...boxShadow[1],
  backgroundColor: theme.colors.primary[2],
  border: "none",
  color: "white",
  fontFamily: theme.font.family,
  fontSize: "inherit",
  padding: "1em 2em",

  ":enabled": {
    cursor: "pointer",
    transition: "transform .2s ease-in-out",

    ":hover": {
      backgroundColor: theme.colors.primary[3],
      "@media (prefers-reduced-motion: no-preference)": {
        transform: "scale(1.05)",
      },
    },
  },

  ":disabled": { backgroundColor: theme.colors.gray[3] },
});

export const buttonLink = ({ color, hoverColor }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  color,
  fontSize: "1rem",
  padding: `${spacing[0]} 0`,

  ":enabled": {
    ":hover": {
      backgroundColor: "transparent",
      color: hoverColor,
      textDecoration: "underline",
    },
  },

  ":disabled": {
    backgroundColor: "transparent",
    ":hover": { textDecoration: "none" },
  },
});

export const border = (theme) => ({
  ...borderRadius[2],
  ...boxShadow[0],
  border: `1px solid ${theme.colors.gray[0]}`,
  padding: spacing[6],
});

export const link = (theme) => ({
  color: theme.colors.black,
  ":hover": { color: theme.colors.gray[9] },
});
