import { useTheme } from "@emotion/react";

const Header = () => {
  const theme = useTheme();

  return (
    <header css={{ margin: "1rem 0 0", textAlign: "center" }}>
      {theme.banners.header}
    </header>
  );
};

export default Header;
