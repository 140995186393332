import { gql } from "@apollo/client";

import { NotAuthenticated } from "./errors";
import { client } from "./graphql";
import { get, post } from "./utils";

export const authenticate = async (login, password) => {
  const response = await post("authenticate", {
    customerId: login,
    password,
  });

  if (response.status === 401)
    throw new NotAuthenticated("Invalid credentials", response);

  return response;
};

export const getProgramTypeCodes = async () =>
  (await get("program-type-codes")).json();

export const getOptimusToken = async (brandCode) => {
  const response = await get("get-optimus-token", { params: { brandCode } });

  if (response.status === 401)
    throw new NotAuthenticated("Invalid credentials", response);

  return response.json();
};

export const endSession = () => post("end-session");

export const isAuthenticated = async () => {
  const { isAuthenticated } = await (await get("is-authenticated")).json();
  return isAuthenticated;
};

export const getMyStatus = async (lookupInfo) => {
  const { customerId, ...params } = lookupInfo;
  return (await get(`customer/${customerId}/status`, { params })).json();
};

export const getMyInfo = async (customerId) =>
  (await get(`customer/${customerId}`)).json();

export const updateMyInfo = (customerId, changes) =>
  post(`customer/${customerId}`, changes);

export const getSecurityQuestions = async () =>
  (await get("security-questions")).json();

export const getMyChallenge = async (customerId) =>
  (await get(`customer/${customerId}/challenge`)).json();

export const verifyMyChallenge = (customerId, answer) =>
  post(`customer/${customerId}/challenge`, { answer });

const ME = gql`
  query MyBrand {
    me {
      id
      brandCode
    }
  }
`;

export const getBrandCode = async () => {
  const {
    data: {
      me: { brandCode },
    },
  } = await client.query({ query: ME });

  return brandCode;
};
