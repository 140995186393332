import { colors, font } from "../base";
import { footer, footerHeight, header, headerHeight } from "../styles";

import { ReactComponent as Banner } from "./static/banner.svg";
import optimusLogo from "./static/optimus-logo.png";

const code = "spring";
const name = "Spring Power & Gas";

const theme = {
  brand: {
    code,
    name,
    url: "https://springpowerandgas.us/",
  },
  colors,
  font,
  banners: {
    header: (
      <Banner
        title={`${name} Ecogold`}
        css={[header, { maxHeight: headerHeight * 1.75 }]}
      />
    ),
    footer: (
      <Banner title={name} css={[footer, { height: footerHeight * 1.75 }]} />
    ),
  },
  dashboardUrls: {
    systemProtection: "https://buycinch.com/springcp",
    applianceProtection: "https://buycinch.com/springap",
    techSupport: "https://buycinch.com/springs",
  },
  optimusLogo,
};

export default theme;
