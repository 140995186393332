import { gray } from "styles";

import { colors } from "./base";

export const headerHeight = 75;
export const footerHeight = 50;

export const header = {
  fill: gray[8],
  maxHeight: headerHeight,
  maxWidth: "90%",
  ".leaf": { fill: colors.secondary[2] },
};

export const footer = {
  fill: gray[6],
  height: footerHeight,
  maxWidth: "90%",
};
