import * as React from "react";

import { gql, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import { animated, config, useSpring } from "react-spring";
import useMeasure from "react-use-measure";

import Authenticated from "components/boundaries/Authenticated";
import { FlexCenteredRow } from "components/styled";
import { ShoppingCartLight } from "icons";
import { appContext } from "store";
import { borderRadius, mq } from "styles";
import { buttonLink, link } from "styles/utils";
import { logout } from "utils";

import NavLink from "./NavLink";
import { padding } from "./styles";

export const INFO = gql`
  query NavData {
    cart {
      id
      count
    }
  }
`;

const Nav = () => {
  const ref = useRef();
  return (
    <FlexCenteredRow
      as="nav"
      ref={ref}
      css={mq({
        backgroundColor: "white",
        flexWrap: "wrap",
        position: ["static", "sticky"],
        top: 0,
        padding: "1rem 0",
        margin: "0 .15rem 2rem",
        zIndex: 1,
      })}
    >
      <NavLink to="dashboard">My Account</NavLink>{" "}
      <NavLink to="store">Store</NavLink>{" "}
      <NavLink to="cart">
        <div css={{ display: "flex", alignItems: "center" }}>
          <ShoppingCartLight css={{ marginRight: ".4em" }} />
          Cart
          <Authenticated programChoice={false} fallback={null}>
            <CartCount />
          </Authenticated>
        </div>
      </NavLink>{" "}
      <NavLink to="profile" partial={false}>
        My Profile
      </NavLink>{" "}
      <Authenticated programChoice={false} fallback={null}>
        <Logout />
      </Authenticated>
    </FlexCenteredRow>
  );
};

const useRef = () => {
  const [ref, { height }] = useMeasure();
  React.useEffect(() => {
    appContext.setNavHeight(height);
  }, [height]);
  return ref;
};

const CartCount = () => {
  const { data } = useQuery(INFO);
  const [style, api] = useSpring(() => {});
  const cartCount = data?.cart?.count;
  React.useEffect(() => {
    cartCount &&
      api.start({
        from: { scale: 1 },
        to: [
          { scale: 1.5, config: config.default },
          { scale: 1, config: config.wobbly },
        ],
      });
  }, [cartCount, api]);

  if (isEmpty(data)) return null;

  return (
    <animated.div
      {...{ style }}
      css={(theme) => [
        borderRadius[3],
        {
          backgroundColor: theme.colors.primary[2],
          color: "white",
          fontSize: ".75em",
          marginLeft: ".4em",
          padding: ".25em",
          minWidth: "1.75em",
          textAlign: "center",
        },
      ]}
      className="cart-count"
    >
      {cartCount}
    </animated.div>
  );
};

const Logout = () => (
  <button onClick={logout} css={(theme) => [button(theme), link(theme)]}>
    Log Out
  </button>
);

const button = (theme) => [
  buttonLink({
    color: theme.colors.primary[2],
    hoverColor: theme.colors.primary[3],
  }),
  { marginTop: "-.1em", padding },
];

export default Nav;
