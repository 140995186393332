import { faIcon } from "./styles";
const SvgMoneyBillWaveLight = ({ title, titleId, ...props }) => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    data-fa-w-20
    css={faIcon}
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm0 192c-35.29 0-64-35.89-64-80s28.71-80 64-80 64 35.89 64 80-28.71 80-64 80zM621.16 54.46C582.37 38.19 543.55 32 504.75 32c-123.17-.01-246.33 62.34-369.5 62.34C70.34 94.34 46.9 79 32.25 79 15.04 79 0 92.32 0 110.81v317.26c0 12.63 7.23 24.6 18.84 29.46C57.63 473.81 96.45 480 135.25 480c123.17 0 246.33-62.35 369.5-62.35 64.91 0 88.34 15.35 103 15.35 17.21 0 32.25-13.32 32.25-31.81V83.93c0-12.64-7.23-24.6-18.84-29.47zm-588.21 56.8c20.22 6.42 41.03 10.53 62.67 12.89-1.97 33.41-29.23 60.04-62.89 60.43l.22-73.32zM32 428.07l.13-42.54c33.58.07 60.88 26.31 63.38 59.43-22.45-3.04-43.63-8.45-63.51-16.89zm575.05-27.33c-20.16-6.4-40.9-10.51-62.47-12.87 2.89-32.5 29.69-58.14 62.69-58.52l-.22 71.39zm.31-103.54c-50 .34-90.59 39.32-94.58 88.6-70.73-1.43-137.18 15.82-200.6 31.87-75.07 19-126.54 31.21-184.41 29.87-1.23-52.02-43.48-94.01-95.55-94.13l.41-136.67c50.65-.34 91.72-40.32 94.78-90.52 70.53 1.41 137.02-15.83 200.4-31.87C402.6 75.41 454.3 63.13 512.03 64.46c.18 52.93 43.01 95.94 95.74 96.07l-.41 136.67zm.51-168.8c-34.24-.07-62.04-27.34-63.58-61.38 22.53 3.03 43.78 8.45 63.71 16.91l-.13 44.47z"
    />
  </svg>
);
export default SvgMoneyBillWaveLight;
