import * as React from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import Loading from "components/widgets/Loading";
import useAppContext, { appContext, programStatuses } from "store";
import { useIsAuthenticated } from "store/hooks";
import { colors } from "themes/base";
import { header } from "themes/styles";
import { logout } from "utils";
import { getOptimusToken } from "utils/api";
import { NotAuthenticated } from "utils/api/errors";

import { ReactComponent as EcogoldLogo } from "./static/ecogold.svg";

const LoginImpl = React.lazy(() => import("components/forms/Login"));

let isNavigating = false;
const navigateToOptimus = async (brandCode) => {
  if (isNavigating) return;

  isNavigating = true;
  try {
    const { url, token } = await getOptimusToken(brandCode);
    window.location = `${url}/login/login_by_stream/${token}`;
  } catch (error) {
    if (error instanceof NotAuthenticated) {
      logout();
      appContext.setServiceDown();
    } else {
      throw error;
    }
  }
};

const selector = ({ brandCode, programStatus }) => ({
  brandCode,
  programStatus,
});
const Authenticated = React.memo(
  ({
    authenticated = true,
    programChoice = true,
    children,
    fallback = <Login />,
  }) => {
    const isAuthenticated = useIsAuthenticated();
    const { brandCode, programStatus } = useAppContext(selector, shallow);

    const shouldDisplay = isAuthenticated && programStatus !== undefined;

    if (
      isAuthenticated &&
      brandCode !== undefined &&
      programStatus === programStatuses.OPTIMUS
    ) {
      navigateToOptimus(brandCode);
      return null;
    }

    if (
      programChoice &&
      isAuthenticated &&
      programStatus === programStatuses.BOTH
    ) {
      return <Choice />;
    }

    return (!authenticated && !isAuthenticated) ||
      (authenticated && shouldDisplay)
      ? children
      : fallback;
  },
);

const Login = () => (
  <React.Suspense fallback={<Loading />}>
    <LoginImpl />
  </React.Suspense>
);

const chooseOptimus = () =>
  appContext.setProgramStatus(programStatuses.OPTIMUS);

const chooseReward = () => appContext.setProgramStatus(programStatuses.REWARD);

const Choice = () => {
  const { brand, optimusLogo } = useTheme();
  const optimusBrand = brand.code.charAt(0).toUpperCase() + brand.code.slice(1);

  return (
    <div
      css={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        margin: "0.5rem",
      }}
    >
      <h2 css={{ textAlign: "center" }}>
        Which rewards program would you like to access?
      </h2>
      <div
        css={{
          columnGap: "3rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          rowGap: "2rem",
        }}
      >
        <Button onClick={chooseOptimus}>
          <img
            src={optimusLogo}
            alt={`Choose ${optimusBrand} Rewards`}
            css={styles.img}
          />
        </Button>

        <Button onClick={chooseReward}>
          <EcogoldLogo
            role="img"
            title="Choose Ecogold"
            css={[header, styles.img]}
          />
        </Button>
      </div>
    </div>
  );
};

const Button = styled.button({
  backgroundColor: colors.secondary[0],

  ":enabled": {
    ":hover": {
      backgroundColor: colors.secondary[1],
      "@media (prefers-reduced-motion: no-preference)": {
        transform: "scale(1.05)",
      },
    },
  },
});

const styles = { img: { filter: "brightness(75%)", height: 44 } };

export default Authenticated;
