import { getBrandCode, getProgramTypeCodes } from "utils/api";

import store, { appContext, programStatuses } from ".";

store.subscribe(
  ({ isAuthenticated }) => isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      (async () => {
        const [brandCode, { programTypeCodes }] = await Promise.all([
          getBrandCode(),
          getProgramTypeCodes(),
        ]);

        programTypeCodes.includes("ECGOPTIMUS")
          ? programTypeCodes.filter((c) => c !== "ECGOPTIMUS").length > 0
            ? appContext.setProgramStatus(programStatuses.BOTH)
            : appContext.setProgramStatus(programStatuses.OPTIMUS)
          : appContext.setProgramStatus(programStatuses.REWARD);

        appContext.setBrandCode(brandCode);
      })();
    } else {
      appContext.setProgramStatus(undefined);
    }
  },
);

store.subscribe(
  ({ brandCode }) => brandCode,
  (brandCode) => brandCode !== undefined && appContext.setTheme(brandCode),
);
