import * as React from "react";

import Loading from "components/widgets/Loading";
import { useActions } from "store/hooks";

const Placeholder = ({
  children,
  fallback = <Loading css={{ minHeight: "10vh" }} />,
}) => {
  const { isResolving, resolve } = useActions();
  React.useEffect(() => {
    isResolving && resolve();
  }, [isResolving, resolve]);

  return (
    <>
      {children}
      {isResolving ? fallback : null}
    </>
  );
};

export default Placeholder;
