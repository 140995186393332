import { debug } from "settings";
import { appContext } from "store";

import { IgnoreMe, ServiceError } from "./errors";

export const get = async (uri, options) => {
  const { params = {}, ...fetchOptions } = options ? options : {};
  const url = makeUrl(`/api/${uri}`, params);
  const response = await customFetch(url, fetchOptions);
  return response;
};

export const post = async (uri, data = {}, options) => {
  const response = await customFetch(`/api/${uri}`, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json; charset=utf-8",
    }),
    body: JSON.stringify(data),
    ...options,
  });
  return response;
};

const makeUrl = (uri, params) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([k, v]) => searchParams.append(k, v));
  return (
    uri + (Object.keys(params).length > 0 ? "?" + searchParams.toString() : "")
  );
};

export const getCsrfToken = async () => get("csrf");

export const customFetch = async (uri, options) => {
  const CSRF_TOKEN = "csrf-token";
  const csrf = {
    token: localStorage.getItem(CSRF_TOKEN) ?? "",
  };
  const headers = new Headers(options?.headers ?? {});
  headers.append(CSRF_TOKEN, csrf.token);
  const init = { credentials: "same-origin", ...options, headers };

  let response;

  try {
    response = await fetch(uri, init);
  } catch (error) {
    // TODO: Sentry

    // graphql-client throws DOMException.name=AbortError when it cancels
    // fetches
    !error instanceof window.DOMException &&
      error.name === "AbortError" &&
      appContext.setServiceDown();
    if (!debug) {
      throw new IgnoreMe("Ignore while not debugging");
    } else {
      throw error;
    }
  }

  csrf.token = response?.headers.get(CSRF_TOKEN) ?? "";
  localStorage.setItem(
    CSRF_TOKEN,
    csrf.token ?? localStorage.getItem(CSRF_TOKEN),
  );

  if (response.status === 403) {
    const body = await response.text();
    if (body.includes("Invalid CSRF token.")) {
      await getCsrfToken();
      return customFetch(uri, options);
    }
  }

  if (response.status === 401) {
    appContext.setNotAuthenticated();
  }

  if (response.status >= 500) {
    appContext.setServiceDown();
    if (!debug) {
      throw new IgnoreMe("Ignore while not debugging");
    } else {
      throw new ServiceError("Service down", response);
    }
  }

  return response;
};
