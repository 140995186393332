import { useTheme } from "@emotion/react";
import { Helmet as HelmetImpl } from "react-helmet-async";

const Helmet = (props) => {
  const theme = useTheme();
  const title = `${theme.brand.name} Ecogold`;

  return (
    <HelmetImpl
      defaultTitle={title}
      titleTemplate={`%s | ${title}`}
      {...props}
    />
  );
};

export default Helmet;
