import Oops from "components/widgets/Oops";
import { debug } from "settings";
import useAppContext from "store";

const selector = ({ isServiceDown }) => isServiceDown;
const ServiceDown = ({ children }) => {
  const isServiceDown = useAppContext(selector);

  return isServiceDown && !debug ? <Oops /> : children;
};

export default ServiceDown;
