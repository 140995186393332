import get from "lodash/get";
import isNil from "lodash/isNil";
import { initialize, pageview } from "react-ga";

const gaIds = process.env.REACT_APP_GA_TRACKING_IDS;

export const initGA = (brandCode) => {
  const code = isNil(gaIds)
    ? undefined
    : get(JSON.parse(gaIds.replace(/'/g, '"')), brandCode);

  if (isNil(code)) {
    return;
  }

  initialize(code);
  pageview(window.location.pathname + window.location.search);
};
