import styled from "@emotion/styled";

import { borderRadius, noUnderline } from "styles";

export const FieldError = styled.div(({ theme }) => [
  borderRadius[1],
  {
    backgroundColor: theme.colors.orange[0],
    color: theme.colors.orange[2],
    fontSize: ".75em",
    marginTop: ".5em",
    padding: ".5em",
    a: { color: theme.colors.orange[2] },
  },
]);

export const FlexCenteredRow = styled.div({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
});

export const FormTitle = styled.h1({ fontSize: "1rem" });

export const NoUnderlineLink = styled.a(noUnderline);

export const Bold = styled.span({ fontWeight: 700 });
export const BoldP = styled.p({ fontWeight: 700 });
