import { appContext } from "store";

const Oops = () => (
  <div css={{ textAlign: "center" }}>
    <h1 css={{ fontSize: "1.5rem" }}>Oops, something went wrong.</h1>
    <p>
      We’re having a little trouble right now. Please{" "}
      <a href={window.location.href} onClick={() => appContext.setServiceUp()}>
        try again later
      </a>
      .
    </p>
    <p>
      If the problem persists,{" "}
      <a href="tel://877-208-7636">call us at (877)&nbsp;208-7636</a>.
    </p>
  </div>
);

export default Oops;
