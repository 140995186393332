import { isIE, isLegacyEdge } from "react-device-detect";

const Unsupported = () => (
  <div>{isIE ? <IE /> : isLegacyEdge ? <Edge /> : null}</div>
);

const IE = () => (
  <p>
    Internet Explorer is not supported. Please download either{" "}
    <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> or{" "}
    <a href="https://www.google.com/chrome">Google Chrome</a> and try again.
  </p>
);

const Edge = () => (
  <p>
    Please update to the latest{" "}
    <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> or{" "}
    <a href="https://www.google.com/chrome">Google Chrome</a> and try again.
  </p>
);

export default Unsupported;
