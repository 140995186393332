import kiwi from "./kiwi";
import spring from "./spring";

export const defaultTheme = kiwi;

export const getThemeForBrand = (brand) => {
  switch (brand.toLowerCase()) {
    case "spring":
      return spring;
    default:
      return kiwi;
  }
};
