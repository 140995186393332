import * as React from "react";

import { ApolloProvider } from "@apollo/client";
import { Global, ThemeProvider } from "@emotion/react";
import { Redirect, Router, globalHistory } from "@reach/router";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { ErrorBoundary } from "@sentry/react";
import { pageview } from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import toast, { Toaster } from "react-hot-toast";
import { useReduceMotion } from "react-reduce-motion";
import { Globals } from "react-spring";

import "store/reactions";
import Placeholder from "components/boundaries/Placeholder";
import ServiceDown from "components/boundaries/ServiceDown";
import Footer from "components/widgets/Footer";
import Header from "components/widgets/Header";
import Helmet from "components/widgets/Helmet";
import Loading from "components/widgets/Loading";
import Logout from "components/widgets/Logout";
import Nav from "components/widgets/Nav";
import Oops from "components/widgets/Oops";
import { useGetMessages, useIsAuthenticated, useTheme } from "store/hooks";
import { setIsAuthenticated } from "store/utils";
import { global } from "styles/utils";
import { getBrandCode } from "utils/api";
import { client } from "utils/api/graphql";
import { initGA } from "utils/services";

import "@reach/skip-nav/styles.css";

const Cart = React.lazy(() => import("components/Cart"));
const Checkout = React.lazy(() => import("components/Checkout"));
const Dashboard = React.lazy(() => import("components/Dashboard"));
const NotFound = React.lazy(() => import("components/widgets/NotFound"));
const Order = React.lazy(() => import("components/Order"));
const Product = React.lazy(() => import("components/itemDetail/Product"));
const Profile = React.lazy(() => import("components/Profile"));
const Redemption = React.lazy(() => import("components/itemDetail/Redemption"));
const Registration = React.lazy(() => import("components/Registration"));
const ResetPassword = React.lazy(() => import("components/ResetPassword"));
const Store = React.lazy(() => import("components/Store"));

const App = () => {
  const theme = useTheme();
  const isAuthenticationVerified = useAuthentication();

  return (
    <>
      <ThemeProvider {...{ theme }}>
        <Global styles={global(theme)} />
        <Header />
        <HelmetProvider>
          <Helmet />
          <ServiceDown>
            <React.Suspense fallback={<Loading />}>
              {isAuthenticationVerified ? (
                <ApolloProvider client={client}>
                  <Content />
                  <Toaster position="bottom-center" />
                  <Messages />
                </ApolloProvider>
              ) : null}
            </React.Suspense>
          </ServiceDown>
        </HelmetProvider>
        <Footer />
      </ThemeProvider>
      <Router primary={false}>
        <Logout path="log-out" />
      </Router>
    </>
  );
};

const Content = () => {
  useSetup();

  return (
    <ErrorBoundary fallback={Oops}>
      <SkipNavLink />
      <Nav />
      <SkipNavContent />
      <main>
        <Placeholder>
          <Router primary={false}>
            <ScrollToTop path="/">
              {/* Authenticated */}
              <Cart path="cart" />
              <Checkout path="checkout" />
              <Dashboard path="dashboard" />
              <Order path="order/:orderId" />
              <Product path="product/:code" />
              <Profile path="profile/*" />
              <Redemption path="redemption/:transactionId" />
              <Store path="store" />

              {/* Unauthenticated */}
              <Registration path="register/*" />
              <ResetPassword path="reset-password/*" />

              <Redirect
                from="springpowerandgas"
                to="/dashboard?brand=spring"
                noThrow
              />
              <Redirect
                from=".well-known/change-password"
                to="/reset-password"
                noThrow
              />
              <NotFound default />
            </ScrollToTop>
          </Router>
        </Placeholder>
      </main>
    </ErrorBoundary>
  );
};

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Messages = () => {
  const messages = useGetMessages();
  const { colors } = useTheme();
  React.useEffect(() => {
    messages.forEach((message) =>
      toast.success(message, {
        style: {
          backgroundColor: colors.secondary[0],
          color: colors.secondary[3],
        },
      }),
    );
  }, [colors, messages]);
  return null;
};

const useAuthentication = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthenticationVerified, setIsAuthenticationVerified] =
    React.useState(false);

  React.useEffect(() => {
    setIsAuthenticated().then(() => setIsAuthenticationVerified(true));
  }, []);

  React.useEffect(() => {
    isAuthenticated && getBrandCode().then((brandCode) => initGA(brandCode));
  }, [isAuthenticated]);

  return isAuthenticationVerified;
};

const useSetup = () => {
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    if (isAuthenticated) {
      const removeListener = globalHistory.listen(({ location }) =>
        pageview(location.pathname + location.search),
      );
      return removeListener;
    }
  }, [isAuthenticated]);

  const prefersReducedMotion = useReduceMotion();
  React.useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    });
  }, [prefersReducedMotion]);
};

export default App;
