import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { Link } from "@reach/router";

import { borderRadius, noUnderline } from "styles";

import { padding } from "./styles";

const NavLink = ({ partial = true, ...props }) => {
  const theme = useTheme();

  const active = css([
    activeLink,
    borderRadius[3],
    {
      backgroundColor: theme.colors.primary[2],
      boxShadow: `0 0 0 3px ${theme.colors.primary[0]}`,

      ".cart-count": {
        backgroundColor: theme.colors.primary[0],
        color: theme.colors.primary[2],
      },
    },
  ]);

  const inactive = css([
    link,
    {
      transition: "transform .2s ease-in-out",
      ":hover": {
        color: theme.colors.primary[3],
        "@media (prefers-reduced-motion: no-preference)": {
          transform: "scale(1.05)",
        },
      },
    },
  ]);

  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = partial ? isPartiallyCurrent : isCurrent;
        return { className: isActive ? active : inactive };
      }}
    />
  );
};

const link = { ...noUnderline, padding };

const activeLink = {
  ...link,
  color: "white",
  cursor: "default",
  ":hover": { color: "white" },
};

export default NavLink;
