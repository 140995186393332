import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { appContext } from "store";

import fragments from "./fragmentSchema";
import { customFetch } from "./utils";

const handleErrors = onError(() => {
  appContext.setServiceDown();
});

const http = createHttpLink({
  fetch: customFetch,
  uri: "/api/graphql",
  credentials: "same-origin",
});

export const client = new ApolloClient({
  link: ApolloLink.from([handleErrors, http]),
  cache: new InMemoryCache({
    possibleTypes: fragments.possibleTypes,
    typePolicies: {
      Cart: {
        fields: {
          products: { merge: false },
          redemptions: { merge: false },
          shipping: { merge: true },
        },
      },
      ExternalProduct: { fields: { pricing: { merge: true } } },
      StandardProduct: { fields: { pricing: { merge: true } } },
      Query: {
        fields: { products: { merge: false }, redemptions: { merge: true } },
      },
    },
  }),
  defaultOptions: {
    watchQuery: { fetchPolicy: "cache-and-network", errorPolicy: "all" },
  },
});
