import { gray } from "styles";

export const colors = {
  black: gray[8],
  primary: ["#dff2f9", "#b5e3f2", "#27aae1", "#0099d3"], // blue
  secondary: ["#f1f6e1", "#d6e59b", "#a8cc42", "#8bbc3c"], //green
  gray,
  orange: ["#fcedd1", "#f4c140", "#dd8628"],
};

export const font = { family: "'Montserrat', sans-serif" };
