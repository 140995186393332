import { keyframes } from "@emotion/react";
import isNil from "lodash/isNil";

import { gray } from "styles";

const Loading = (props) => (
  <div
    css={(theme) => ({
      alignItems: "center",
      color: isNil(theme.colors) ? gray[5] : theme.colors.primary[2],
      display: "flex",
      fontSize: "1.5rem",
      minHeight: "30vh",
      justifyContent: "center",
    })}
    {...props}
  >
    <div css={loader}>Loading…</div>
  </div>
);

const effect = keyframes`
  0%, 80%, 100% {
    box-shadow: 0 1em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
`;

const all = {
  animation: `${effect} 1.8s infinite ease-in-out`,
  animationFillMode: "both",
  borderRadius: "50%",
  height: "1em",
  width: "1em",
};

const beforeAfter = {
  ...all,
  content: '""',
  position: "absolute",
  top: 0,
};

const loader = {
  ...all,
  animationDelay: "-0.16s",
  marginTop: "-2em",
  position: "relative",
  textIndent: "-9999em",

  ":before": {
    ...beforeAfter,
    left: "-2em",
    animationDelay: "-0.32s",
  },

  ":after": {
    ...beforeAfter,
    left: "2em",
  },
};

export default Loading;
